import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { OrbitControls, Sky, Stars, Environment, Plane as DreiPlane, TransformControls } from '@react-three/drei'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { FaPlay, FaPause, FaStop, FaTrash, FaCheck, FaEye, FaEyeSlash,FaPlus } from 'react-icons/fa';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import styled from 'styled-components'
import axios from 'axios'
import './App.css'

function CameraSetup() {
  const { camera } = useThree();
  
  useEffect(() => {
    camera.position.set(0, 10, 20);
    camera.lookAt(new THREE.Vector3(0, 0, 0));
    camera.fov = 50;
    camera.updateProjectionMatrix();
  }, [camera]);

  return null;
}

const firebaseConfig = {
  apiKey: "AIzaSyCyfiISWGwjl8KMrRax4YecLvib_l8TMGU",
  authDomain: "supersaga-landing-page.firebaseapp.com",
  projectId: "supersaga-landing-page",
  storageBucket: "supersaga-landing-page.appspot.com",
  messagingSenderId: "444071058243",
  appId: "1:444071058243:web:b3b4f4cb4db2bf68566df6",
  measurementId: "G-QW3WE2S630"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Model = React.memo(function Model({ id, url, fileType, isSelected, transformMode, onSelect, onLoad, animationState }) {
  const groupRef = useRef()
  const modelRef = useRef()
  const mixerRef = useRef()
  const [animationAction, setAnimationAction] = useState(null)

  useEffect(() => {
    let loader
    if (fileType === 'gltf' || fileType === 'glb') {
      loader = new GLTFLoader()
    } else if (fileType === 'fbx') {
      loader = new FBXLoader()
    } else {
      console.error('Unsupported file type:', fileType)
      return
    }

    loader.load(
      url,
      (loadedObject) => {
        const model = fileType === 'fbx' ? loadedObject : loadedObject.scene
        model.traverse((child) => {
          if (child.isMesh) {
            child.castShadow = true
            child.receiveShadow = true
            child.material.side = THREE.DoubleSide
            child.material.transparent = false
            child.material.needsUpdate = true
          }
        })

        const box = new THREE.Box3().setFromObject(model)
        const size = box.getSize(new THREE.Vector3())
        const maxDim = Math.max(size.x, size.y, size.z)
        const scale = 5 / maxDim
        model.scale.set(scale, scale, scale)

        const center = box.getCenter(new THREE.Vector3())
        model.position.set(-center.x * scale, -box.min.y * scale, -center.z * scale)

        if (modelRef.current && groupRef.current) {
          groupRef.current.remove(modelRef.current)
        }
        modelRef.current = model
        groupRef.current.add(model)

        if (loadedObject.animations && loadedObject.animations.length > 0) {
          mixerRef.current = new THREE.AnimationMixer(model)
          const action = mixerRef.current.clipAction(loadedObject.animations[0])
          action.clampWhenFinished = true
          setAnimationAction(action)
          action.play()
        }

        onLoad(id, loadedObject.animations && loadedObject.animations.length > 0)
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
      },
      (error) => {
        console.error('An error happened', error)
      }
    )

    return () => {
      if (modelRef.current && groupRef.current) {
        groupRef.current.remove(modelRef.current)
      }
      if (mixerRef.current) {
        mixerRef.current.stopAllAction()
      }
    }
  }, [url, fileType, onLoad, id])

  useEffect(() => {
    if (animationAction) {
      switch (animationState) {
        case 'playing':
          animationAction.paused = false
          animationAction.play()
          break
        case 'paused':
          animationAction.paused = true
          break
        case 'stopped':
          animationAction.stop()
          break
        default:
          break
      }
    }
  }, [animationState, animationAction])

  useFrame((state, delta) => {
    if (mixerRef.current) {
      mixerRef.current.update(delta)
    }
  })

  return (
    <>
      <group ref={groupRef} onClick={onSelect}>
        {/* This ensures the group always exists */}
        <object3D />
      </group>
      {isSelected && (
        <TransformControls object={groupRef} mode={transformMode} />
      )}
    </>
  )
})

function Scene({ 
  models, 
  lights,
  selectedItem, 
  transformMode, 
  onSelectItem, 
  currentEnvironment, 
  environmentSettings, 
  onEnvironmentLoad, 
  onLoadProgress,
  onModelLoad,
  onUpdateLightPosition,
  animatedModels
}) {
  const { scene, camera } = useThree()
  const currentSettings = environmentSettings[currentEnvironment]
  const lightRefs = useRef({})

  useEffect(() => {
    scene.background = new THREE.Color(currentSettings.bgColor)
    onEnvironmentLoad()
  }, [scene, currentSettings, onEnvironmentLoad])

  const handleLightTransform = (lightId) => {
    if (lightRefs.current[lightId]) {
      const newPosition = lightRefs.current[lightId].position.toArray()
      onUpdateLightPosition(lightId, newPosition)
    }
  }
  return (
    <>
      <CameraSetup />
      <Sky sunPosition={currentSettings.sunPosition} inclination={currentEnvironment === 'night' ? 0 : 0.6} />
      {currentEnvironment === 'night' && <Stars />}
      <Environment preset={currentEnvironment === 'day' ? 'sunset' : currentEnvironment} background={false} />
      
      <ambientLight intensity={currentSettings.ambientIntensity} />
      
      <hemisphereLight 
        color={currentSettings.bgColor}
        groundColor="#4B0082"
        intensity={currentSettings.hemisphereIntensity}
      />
      
      {currentEnvironment !== 'night' && (
        <directionalLight
          color="#FDB813"
          intensity={currentSettings.sunIntensity}
          position={currentSettings.sunPosition}
          castShadow
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
        />
      )}

      <DreiPlane 
        args={[100, 100]} 
        rotation={[-Math.PI / 2, 0, 0]} 
        position={[0, 0, 0]}
        receiveShadow
      >
        <meshStandardMaterial color="#cccccc" />
      </DreiPlane>

      {models.map((model) => (
        <Model 
          key={model.id}
          {...model}
          isSelected={selectedItem === model.id}
          transformMode={transformMode}
          onSelect={() => onSelectItem(model.id)}
          onLoad={onModelLoad}
          animationState={animatedModels[model.id]?.state}
        />
      ))}

      {lights.map((light) => (
        <group key={light.id}>
          {light.type === 'PointLight' ? (
            <pointLight 
              ref={(el) => lightRefs.current[light.id] = el}
              position={light.position} 
              intensity={light.intensity} 
              color={light.color}
            />
          ) : (
            <directionalLight
              ref={(el) => lightRefs.current[light.id] = el}
              position={light.position}
              intensity={light.intensity}
              color={light.color}
              target-position={light.target}
            />
          )}
          {selectedItem === light.id && (
            <TransformControls
              object={lightRefs.current[light.id]}
              mode={transformMode}
              onObjectChange={() => handleLightTransform(light.id)}
            />
          )}
          <mesh 
            position={light.position} 
            onClick={(e) => {
              e.stopPropagation()
              onSelectItem(light.id)
            }}
          >
            <sphereGeometry args={[0.1, 32, 32]} />
            <meshBasicMaterial visible={false} />
          </mesh>
        </group>
      ))}

      <OrbitControls makeDefault />
    </>
  )
}

export default function SimplePlaneWithModels() {
  const [models, setModels] = useState([])
  const [lights, setLights] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [transformMode, setTransformMode] = useState('translate')
  const [currentEnvironment, setCurrentEnvironment] = useState('day')
  const [isEnvironmentLoading, setIsEnvironmentLoading] = useState(false)
  const [loadingProgress, setLoadingProgress] = useState(0)
  const [showLightOptions, setShowLightOptions] = useState(false)
  const [animatedModels, setAnimatedModels] = useState({})
  const canvasRef = useRef(null)
  const sceneRef = useRef(new THREE.Scene())
  const cameraRef = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000))
  const mediaRecorderRef = useRef(null)
  const chunksRef = useRef([])
  const [isRecording, setIsRecording] = useState(false)
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null)
  const [isConverting, setIsConverting] = useState(false)
  const fileInputRef = useRef(null)
  const rendererRef = useRef()
  const environmentSettings = useMemo(() => ({
    night: { 
      bgColor: "#000033", 
      sunPosition: [0, -10, 0], 
      sunIntensity: 0,
      ambientIntensity: 0.1,
      hemisphereIntensity: 0.1
    },
    dawn: { 
      bgColor: "#FF7F50", 
      sunPosition: [-10, 5, 10], 
      sunIntensity: 3,
      ambientIntensity: 0.3,
      hemisphereIntensity: 0.7
    },
    sunset: { 
      bgColor: "#FF4500", 
      sunPosition: [10, 5, -10], 
      sunIntensity: 3,
      ambientIntensity: 0.3,
      hemisphereIntensity: 0.7
    },
    day: { 
      bgColor: "#87CEEB", 
      sunPosition: [0, 50, 0], 
      sunIntensity: 5,
      ambientIntensity: 0.5,
      hemisphereIntensity: 1
    }
  }), [])

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0]
    if (file) {
      const newModel = {
        id: Date.now().toString(),
        name: file.name,
        url: URL.createObjectURL(file),
        fileType: file.name.split('.').pop().toLowerCase(),
        visible: true
      }
      setModels(prevModels => [...prevModels, newModel])
      
      // Clear the file input
      event.target.value = ''
    }
  }, [])

  const removeModel = useCallback((id) => {
    setModels(prevModels => prevModels.filter(model => model.id !== id))
    if (selectedItem === id) {
      setSelectedItem(null)
    }
  }, [selectedItem])

  const handleItemSelect = useCallback((id) => {
    setSelectedItem(prevSelected => prevSelected === id ? null : id)
    setTransformMode('translate')
  }, [])

  const toggleModelVisibility = useCallback((id) => {
    setModels(prevModels => prevModels.map(model => 
      model.id === id ? { ...model, visible: !model.visible } : model
    ))
  }, [])

  const handleEnvironmentChange = useCallback((preset) => {
    setIsEnvironmentLoading(true)
    setLoadingProgress(0)
    setCurrentEnvironment(preset)
  }, [])

  const handleModelLoad = useCallback((modelId, hasAnimations) => {
    if (hasAnimations) {
      setAnimatedModels(prev => ({ ...prev, [modelId]: { state: 'playing' } }))
    }
    console.log(`Model ${modelId} loaded successfully`)
  }, [])

  const setAnimationState = useCallback((modelId, state) => {
    setAnimatedModels(prev => ({
      ...prev,
      [modelId]: { ...prev[modelId], state }
    }))
  }, [])

  const addLight = useCallback((type) => {
    const newLight = {
      id: Date.now().toString(),
      type: type,
      position: [0, 5, 0],
      intensity: 1,
      color: '#ffffff'
    }
    if (type === 'DirectionalLight') {
      newLight.target = [0, 0, 0]
    }
    setLights(prevLights => [...prevLights, newLight])
    setShowLightOptions(false)
  }, [])

  const removeLight = useCallback((id) => {
    setLights(prevLights => prevLights.filter(light => light.id !== id))
    if (selectedItem === id) {
      setSelectedItem(null)
    }
  }, [selectedItem])

  const updateLightPosition = useCallback((id, newPosition) => {
    setLights(prevLights => prevLights.map(light => 
      light.id === id ? { ...light, position: newPosition } : light
    ))
  }, [])

  const updateLightIntensity = useCallback((id, newIntensity) => {
    setLights(prevLights => prevLights.map(light => 
      light.id === id ? { ...light, intensity: newIntensity } : light
    ))
  }, [])

  const updateLightColor = useCallback((id, newColor) => {
    setLights(prevLights => prevLights.map(light => 
      light.id === id ? { ...light, color: newColor } : light
    ))
  }, [])

  const startRecording = useCallback(() => {
    if (canvasRef.current) {
      const stream = canvasRef.current.captureStream(30) // 30 FPS
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType: 'video/webm' })
      
      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunksRef.current.push(event.data)
        }
      }

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, { type: 'video/webm' })
        setRecordedVideoUrl(URL.createObjectURL(blob))
        chunksRef.current = []
      }

      mediaRecorderRef.current.start()
      setIsRecording(true)
    }
  }, [])

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      setIsRecording(false)
    }
  }, [isRecording])
  

  const downloadVideo = useCallback((format = 'webm') => {
    if (recordedVideoUrl) {
      const a = document.createElement('a')
      a.href = recordedVideoUrl
      a.download = `scene_recording.${format}`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }, [recordedVideoUrl])

  const convertToMp4 = useCallback(async () => {
    if (recordedVideoUrl) {
      setIsConverting(true)
      try {
        // Fetch the WebM file
        const response = await fetch(recordedVideoUrl)
        const webmBlob = await response.blob()

        // Create a File object from the Blob
        const webmFile = new File([webmBlob], 'input.webm', { type: 'video/webm' })

        // Step 1: Create an upload task
        const createUploadTaskResponse = await axios.post('https://api.cloudconvert.com/v2/import/upload', {
          file: webmFile.name
        }, {
          headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmMwMjM0NDhjYWMzY2JiMWZmNWMzOWNjYzQzODRkZWNmZjFmYWViOTU4OGQxMjI5NDE0NWQ2Njc0ZDcyOTg1ZmU5OWZhZDMyZmU0OGFhY2YiLCJpYXQiOjE3MjYzNDQ0OTYuNTAxNDYsIm5iZiI6MTcyNjM0NDQ5Ni41MDE0NjEsImV4cCI6NDg4MjAxODA5Ni40OTc0OTksInN1YiI6IjY5NTg3OTU5Iiwic2NvcGVzIjpbInVzZXIucmVhZCIsInRhc2sucmVhZCIsInRhc2sud3JpdGUiLCJ3ZWJob29rLnJlYWQiLCJ3ZWJob29rLndyaXRlIiwicHJlc2V0LnJlYWQiLCJwcmVzZXQud3JpdGUiXX0.JBdYPYGQ4jYys08KvAwI4MQKDuZqhCvwjhv0KmH9YDQWrAcnIXRlFFpcIECwSDaF3RUAMv6djVu2Td835XsekknvadTRlCUA4k0fLTvMdgkKlWDSQj8mQOR9ApMjf9gDel6NPbOIkavt8zoebyjRBvBVHflH2aq2vhYIr_NLyFksec5euyI-qTn6KUpUUGpUOYHKBGGPCPDA1lsBVk3dGA2oCqDZx-HjGOOL5htPYqNAVU1polvvLVoamdSvfdhj6OKQU9IB9rXH-hNv45Yz5X7dy0POiLAzVQO0mv2MfXg3ow5Fs7QZzErziHNpPeI0wDUXsLYNR0YZFMB0VJnX-zkMwvyXKK8SvxD59DUjPBylKSmK4S_tOVLRrCAsDlIlGl3hWj07ny56cBKGEwX7H2-SP-lhUakkORzTNemseZi0pzkp6w9HuK0WDlavmXALTg779RcRWMnT-xpCm8zH-PmGQSH7fzzOz3lzD9ZoSEZTPHC5vIEuM3ZLpvTwlj2QC7rVWW2hqsrlgFX1B5QdLBzL7UBuuKw0W0JSVaLWHra2x3EhaVJyLMhbU33itg9pt20URVv6wo4vRGAR7swk0YU6dkB7lOsYfZGHlV20Npi8IKOL9IKUVYLDTODV_msLqItiMv9ghz9bNQupHJmG2sPRRvJ2BXj4iYfc1BURn5k',
            'Content-Type': 'application/json'
          }
        })

        console.log('Upload task response:', JSON.stringify(createUploadTaskResponse.data, null, 2))

        if (!createUploadTaskResponse.data.data || !createUploadTaskResponse.data.data.result || !createUploadTaskResponse.data.data.result.form) {
          throw new Error('Unexpected response format from upload task creation')
        }

        const { url, parameters } = createUploadTaskResponse.data.data.result.form

        // Create a FormData object and append the file and parameters
        const formData = new FormData()
        Object.keys(parameters).forEach(key => {
          formData.append(key, parameters[key])
        })
        formData.append('file', webmFile)

        // Upload the file
        const uploadResponse = await axios.post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        console.log('File upload response:', uploadResponse.data)

        // Wait for the upload task to complete
        const uploadTaskId = createUploadTaskResponse.data.data.id
        let uploadTaskStatus
        do {
          await new Promise(resolve => setTimeout(resolve, 1000))
          const taskStatusResponse = await axios.get(`https://api.cloudconvert.com/v2/tasks/${uploadTaskId}`, {
            headers: {
              'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmMwMjM0NDhjYWMzY2JiMWZmNWMzOWNjYzQzODRkZWNmZjFmYWViOTU4OGQxMjI5NDE0NWQ2Njc0ZDcyOTg1ZmU5OWZhZDMyZmU0OGFhY2YiLCJpYXQiOjE3MjYzNDQ0OTYuNTAxNDYsIm5iZiI6MTcyNjM0NDQ5Ni41MDE0NjEsImV4cCI6NDg4MjAxODA5Ni40OTc0OTksInN1YiI6IjY5NTg3OTU5Iiwic2NvcGVzIjpbInVzZXIucmVhZCIsInRhc2sucmVhZCIsInRhc2sud3JpdGUiLCJ3ZWJob29rLnJlYWQiLCJ3ZWJob29rLndyaXRlIiwicHJlc2V0LnJlYWQiLCJwcmVzZXQud3JpdGUiXX0.JBdYPYGQ4jYys08KvAwI4MQKDuZqhCvwjhv0KmH9YDQWrAcnIXRlFFpcIECwSDaF3RUAMv6djVu2Td835XsekknvadTRlCUA4k0fLTvMdgkKlWDSQj8mQOR9ApMjf9gDel6NPbOIkavt8zoebyjRBvBVHflH2aq2vhYIr_NLyFksec5euyI-qTn6KUpUUGpUOYHKBGGPCPDA1lsBVk3dGA2oCqDZx-HjGOOL5htPYqNAVU1polvvLVoamdSvfdhj6OKQU9IB9rXH-hNv45Yz5X7dy0POiLAzVQO0mv2MfXg3ow5Fs7QZzErziHNpPeI0wDUXsLYNR0YZFMB0VJnX-zkMwvyXKK8SvxD59DUjPBylKSmK4S_tOVLRrCAsDlIlGl3hWj07ny56cBKGEwX7H2-SP-lhUakkORzTNemseZi0pzkp6w9HuK0WDlavmXALTg779RcRWMnT-xpCm8zH-PmGQSH7fzzOz3lzD9ZoSEZTPHC5vIEuM3ZLpvTwlj2QC7rVWW2hqsrlgFX1B5QdLBzL7UBuuKw0W0JSVaLWHra2x3EhaVJyLMhbU33itg9pt20URVv6wo4vRGAR7swk0YU6dkB7lOsYfZGHlV20Npi8IKOL9IKUVYLDTODV_msLqItiMv9ghz9bNQupHJmG2sPRRvJ2BXj4iYfc1BURn5k',
            }
          })
          uploadTaskStatus = taskStatusResponse.data.data.status
          console.log('Upload task status:', uploadTaskStatus)
        } while (uploadTaskStatus === 'waiting' || uploadTaskStatus === 'processing')

        if (uploadTaskStatus !== 'finished') {
          throw new Error(`Upload task failed with status: ${uploadTaskStatus}`)
        }

        // Step 2: Create a conversion job
        const createJobResponse = await axios.post('https://api.cloudconvert.com/v2/jobs', {
          tasks: {
            'import-1': {
              operation: 'import/upload',
              file: webmFile.name,
              result: true
            },
            'convert-1': {
              operation: 'convert',
              input: 'import-1',
              output_format: 'mp4',
              engine: 'ffmpeg',
              engine_version: '4.4.2'
            },
            'export-1': {
              operation: 'export/url',
              input: 'convert-1'
            }
          }
        }, {
          headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmMwMjM0NDhjYWMzY2JiMWZmNWMzOWNjYzQzODRkZWNmZjFmYWViOTU4OGQxMjI5NDE0NWQ2Njc0ZDcyOTg1ZmU5OWZhZDMyZmU0OGFhY2YiLCJpYXQiOjE3MjYzNDQ0OTYuNTAxNDYsIm5iZiI6MTcyNjM0NDQ5Ni41MDE0NjEsImV4cCI6NDg4MjAxODA5Ni40OTc0OTksInN1YiI6IjY5NTg3OTU5Iiwic2NvcGVzIjpbInVzZXIucmVhZCIsInRhc2sucmVhZCIsInRhc2sud3JpdGUiLCJ3ZWJob29rLnJlYWQiLCJ3ZWJob29rLndyaXRlIiwicHJlc2V0LnJlYWQiLCJwcmVzZXQud3JpdGUiXX0.JBdYPYGQ4jYys08KvAwI4MQKDuZqhCvwjhv0KmH9YDQWrAcnIXRlFFpcIECwSDaF3RUAMv6djVu2Td835XsekknvadTRlCUA4k0fLTvMdgkKlWDSQj8mQOR9ApMjf9gDel6NPbOIkavt8zoebyjRBvBVHflH2aq2vhYIr_NLyFksec5euyI-qTn6KUpUUGpUOYHKBGGPCPDA1lsBVk3dGA2oCqDZx-HjGOOL5htPYqNAVU1polvvLVoamdSvfdhj6OKQU9IB9rXH-hNv45Yz5X7dy0POiLAzVQO0mv2MfXg3ow5Fs7QZzErziHNpPeI0wDUXsLYNR0YZFMB0VJnX-zkMwvyXKK8SvxD59DUjPBylKSmK4S_tOVLRrCAsDlIlGl3hWj07ny56cBKGEwX7H2-SP-lhUakkORzTNemseZi0pzkp6w9HuK0WDlavmXALTg779RcRWMnT-xpCm8zH-PmGQSH7fzzOz3lzD9ZoSEZTPHC5vIEuM3ZLpvTwlj2QC7rVWW2hqsrlgFX1B5QdLBzL7UBuuKw0W0JSVaLWHra2x3EhaVJyLMhbU33itg9pt20URVv6wo4vRGAR7swk0YU6dkB7lOsYfZGHlV20Npi8IKOL9IKUVYLDTODV_msLqItiMv9ghz9bNQupHJmG2sPRRvJ2BXj4iYfc1BURn5k',
            'Content-Type': 'application/json'
          }
        })

        console.log('Conversion job response:', JSON.stringify(createJobResponse.data, null, 2))

        if (!createJobResponse.data.data || !createJobResponse.data.data.id) {
          throw new Error('Unexpected response format from job creation')
        }

        // Wait for the job to complete
        let exportTask
        let attempts = 0
        const maxAttempts = 60 // Increased to allow more time
        do {
          await new Promise(resolve => setTimeout(resolve, 2000)) // Wait for 2 seconds
          const jobStatusResponse = await axios.get(`https://api.cloudconvert.com/v2/jobs/${createJobResponse.data.data.id}`, {
            headers: {
              'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmMwMjM0NDhjYWMzY2JiMWZmNWMzOWNjYzQzODRkZWNmZjFmYWViOTU4OGQxMjI5NDE0NWQ2Njc0ZDcyOTg1ZmU5OWZhZDMyZmU0OGFhY2YiLCJpYXQiOjE3MjYzNDQ0OTYuNTAxNDYsIm5iZiI6MTcyNjM0NDQ5Ni41MDE0NjEsImV4cCI6NDg4MjAxODA5Ni40OTc0OTksInN1YiI6IjY5NTg3OTU5Iiwic2NvcGVzIjpbInVzZXIucmVhZCIsInRhc2sucmVhZCIsInRhc2sud3JpdGUiLCJ3ZWJob29rLnJlYWQiLCJ3ZWJob29rLndyaXRlIiwicHJlc2V0LnJlYWQiLCJwcmVzZXQud3JpdGUiXX0.JBdYPYGQ4jYys08KvAwI4MQKDuZqhCvwjhv0KmH9YDQWrAcnIXRlFFpcIECwSDaF3RUAMv6djVu2Td835XsekknvadTRlCUA4k0fLTvMdgkKlWDSQj8mQOR9ApMjf9gDel6NPbOIkavt8zoebyjRBvBVHflH2aq2vhYIr_NLyFksec5euyI-qTn6KUpUUGpUOYHKBGGPCPDA1lsBVk3dGA2oCqDZx-HjGOOL5htPYqNAVU1polvvLVoamdSvfdhj6OKQU9IB9rXH-hNv45Yz5X7dy0POiLAzVQO0mv2MfXg3ow5Fs7QZzErziHNpPeI0wDUXsLYNR0YZFMB0VJnX-zkMwvyXKK8SvxD59DUjPBylKSmK4S_tOVLRrCAsDlIlGl3hWj07ny56cBKGEwX7H2-SP-lhUakkORzTNemseZi0pzkp6w9HuK0WDlavmXALTg779RcRWMnT-xpCm8zH-PmGQSH7fzzOz3lzD9ZoSEZTPHC5vIEuM3ZLpvTwlj2QC7rVWW2hqsrlgFX1B5QdLBzL7UBuuKw0W0JSVaLWHra2x3EhaVJyLMhbU33itg9pt20URVv6wo4vRGAR7swk0YU6dkB7lOsYfZGHlV20Npi8IKOL9IKUVYLDTODV_msLqItiMv9ghz9bNQupHJmG2sPRRvJ2BXj4iYfc1BURn5k',
            }
          })
          console.log('Job status response:', JSON.stringify(jobStatusResponse.data, null, 2))
          
          if (!jobStatusResponse.data.data || !jobStatusResponse.data.data.tasks) {
            throw new Error('Unexpected job status response format')
          }
          
          exportTask = jobStatusResponse.data.data.tasks.find(task => task.operation === 'export/url')
          
          if (!exportTask) {
            throw new Error('Export task not found in job status response')
          }
          
          console.log('Export task status:', exportTask.status)
          
          attempts++
          if (attempts >= maxAttempts) {
            throw new Error('Job completion timeout')
          }
        } while (exportTask.status !== 'finished')

        if (!exportTask.result || !exportTask.result.files || !exportTask.result.files[0].url) {
          throw new Error('Unexpected export task result format')
        }

        // Download the converted file
        const mp4Url = exportTask.result.files[0].url
        console.log('MP4 download URL:', mp4Url)
        
        const mp4Response = await fetch(mp4Url)
        const mp4Blob = await mp4Response.blob()
        const downloadUrl = URL.createObjectURL(mp4Blob)

        const a = document.createElement('a')
        a.href = downloadUrl
        a.download = 'scene_recording.mp4'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(downloadUrl)

      } catch (error) {
        console.error('Error converting video:', error)
        if (error.response) {
          console.error('Full error response:', JSON.stringify(error.response.data, null, 2))
        } else if (error.request) {
          console.error('Error request:', error.request)
        } else {
          console.error('Error message:', error.message)
        }
        alert('Failed to convert video. Please check the console for more details.')
      } finally {
        setIsConverting(false)
      }
    }
  }, [recordedVideoUrl])

  const handleImportClick = () => {
    fileInputRef.current.click();
  };

  

  const StyledButton = styled.button`
  background-color: #e0e0e0;
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px 4px 0;

  &:hover {
    background-color: #d0d0d0;
  }

  &:active {
    background-color: #c0c0c0;
  }
`;

const IconButton = styled(StyledButton)`
  padding: 8px;
  font-size: 14px;
`;



const Panel = styled.div`
  width: 300px;
  padding: 20px;
  border-right: 2px solid black;
  overflow-y: auto;
  background-color: white;
  color: black;
`;

const ModelList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ModelItem = styled.li`
  margin-bottom: 10px;
  background-color: ${props => props.selected ? '#f0f0f0' : 'transparent'};
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${props => props.selected ? '2px solid black' : 'none'};
`;

const AppTitle = styled.h1`
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: 800; // Increased boldness
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 18px; // Reduced from 20px to move it up 2px
  margin-top: -2px; // Added to move it up 2px
  text-align: center;
`;

const buttonStyle = {
  backgroundColor: '#1a2b3c',
  color: 'white',
  border: 'none',
  borderRadius: '8px',
  padding: '12px',
  fontSize: '16px',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 4px 4px 0',
  position: 'relative',
  overflow: 'hidden',
};
const LightHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const LightList = styled.ul`
  list-style: none;
  padding: 0;
`;

const LightItem = styled.li`
  margin-bottom: 10px;
  background-color: ${props => props.selected ? '#f0f0f0' : 'transparent'};
  padding: 10px;
  border-radius: 8px;
  border: ${props => props.selected ? '2px solid black' : 'none'};
`;

const ModelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const KeyBindings = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
`;

  const captureScene = useCallback(() => {
    if (sceneRef.current && cameraRef.current) {
      if (!rendererRef.current) {
        rendererRef.current = new THREE.WebGLRenderer({ antialias: true })
        rendererRef.current.setSize(window.innerWidth, window.innerHeight)
      }

      rendererRef.current.render(sceneRef.current, cameraRef.current)
      
      const imgData = rendererRef.current.domElement.toDataURL('image/jpeg', 0.92)
      const link = document.createElement('a')
      link.href = imgData
      link.download = 'scene.jpg'
      link.click()
    }
  }, [])
  const togglePlayPause = useCallback((modelId) => {
    setAnimatedModels(prev => ({
      ...prev,
      [modelId]: { 
        ...prev[modelId], 
        state: prev[modelId]?.state === 'playing' ? 'paused' : 'playing' 
      }
    }));
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key.toLowerCase()) {
        case 'w':
          setTransformMode('translate');
          break;
        case 'e':
          setTransformMode('rotate');
          break;
        case 'r':
          setTransformMode('scale');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div style={{ display: 'flex', height: '100vh', backgroundColor: 'white', color: 'black' }}>
      <Panel>
      <AppTitle>Supersaga Lite</AppTitle>
      <ModelHeader>
        <h3>Imported Models</h3>
        <IconButton onClick={handleImportClick}>
          <FaPlus />
        </IconButton>
      </ModelHeader>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept=".glb,.gltf,.fbx"
        />
        <ModelList>
          {models.map((model) => (
            <ModelItem key={model.id} selected={selectedItem === model.id}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => handleItemSelect(model.id)} style={{ marginRight: '10px' }}>
                  {selectedItem === model.id ? <FaCheck /> : '☐'}
                </IconButton>
                <span>{model.name}</span>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {selectedItem === model.id && (
                  <>
                    <IconButton onClick={() => removeModel(model.id)}>
                      <FaTrash />
                    </IconButton>
                    <IconButton onClick={() => toggleModelVisibility(model.id)}>
                      {model.visible ? <FaEye /> : <FaEyeSlash />}
                    </IconButton>
                  </>
                )}
                {animatedModels[model.id] && (
                  <>
                    <IconButton onClick={() => togglePlayPause(model.id)}>
                      {animatedModels[model.id].state === 'playing' ? <FaPause /> : <FaPlay />}
                    </IconButton>
                    <IconButton onClick={() => setAnimationState(model.id, 'stopped')}>
                      <FaStop />
                    </IconButton>
                  </>
                )}
              </div>
            </ModelItem>
          ))}
        </ModelList>
        
        <LightHeader>
          <h3>Lights</h3>
          <IconButton onClick={() => setShowLightOptions(!showLightOptions)}>
            <FaPlus />
          </IconButton>
        </LightHeader>
      {showLightOptions && (
      <div>
        <StyledButton onClick={() => {
          addLight('PointLight');
          setShowLightOptions(false);
        }}>
          Point Light
        </StyledButton>
        <StyledButton onClick={() => {
          addLight('DirectionalLight');
          setShowLightOptions(false);
        }}>
          Directional Light
        </StyledButton>
      </div>
    )}
      <LightList>
        {lights.map((light) => (
          <LightItem key={light.id} selected={selectedItem === light.id}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <IconButton onClick={() => handleItemSelect(light.id)}>
                {selectedItem === light.id ? <FaCheck /> : '☐'}
              </IconButton>
              <span>{light.type}</span>
              <IconButton onClick={() => removeLight(light.id)}>
                <FaTrash />
              </IconButton>
            </div>
            {selectedItem === light.id && (
              <div style={{ marginTop: '10px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                  <span style={{ marginRight: '10px', minWidth: '60px' }}>Intensity:</span>
                  <input 
                    type="range" 
                    min="0" 
                    max="5" 
                    step="0.1" 
                    value={light.intensity} 
                    onChange={(e) => updateLightIntensity(light.id, parseFloat(e.target.value))} 
                    style={{ flex: 1 }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '10px', minWidth: '60px' }}>Color:</span>
                  <input 
                    type="color" 
                    value={light.color} 
                    onChange={(e) => updateLightColor(light.id, e.target.value)} 
                  />
                </div>
              </div>
            )}
          </LightItem>
        ))}
      </LightList>
        
        <h3>Environment Presets</h3>
        <select value={currentEnvironment} onChange={(e) => handleEnvironmentChange(e.target.value)} style={selectStyle}>
          <option value="day">Day</option>
          <option value="night">Night</option>
          <option value="dawn">Dawn</option>
          <option value="sunset">Sunset</option>
        </select>
        
        <h3>Scene Controls</h3>
        <StyledButton onClick={isRecording ? stopRecording : startRecording}>
          {isRecording ? 'Stop Recording' : 'Start Recording'}
        </StyledButton>
        <StyledButton onClick={captureScene}>
          Capture Scene
        </StyledButton>
        {recordedVideoUrl && (
          <>
            <div>
              <video src={recordedVideoUrl} controls style={{ width: '100%' }} />
            </div>
            <StyledButton onClick={() => downloadVideo('webm')}>Download WebM</StyledButton>
            <StyledButton onClick={convertToMp4} disabled={isConverting}>
              {isConverting ? 'Converting...' : 'Convert to MP4'}
            </StyledButton>
          </>
        )}
        {selectedItem && (
          <KeyBindings>
            <h4>Key Bindings:</h4>
            <p>W: Translate</p>
            <p>E: Rotate</p>
            <p>R: Scale</p>
          </KeyBindings>
        )}
        </Panel>
      
      {/* Main 3D Scene */}
      <div style={{ flex: 1, position: 'relative' }}>
        <Canvas ref={canvasRef} shadows onCreated={({ scene, camera }) => {
          sceneRef.current = scene;
          cameraRef.current = camera;
        }}>
          <Scene 
            models={models} 
            lights={lights}
            selectedItem={selectedItem}
            transformMode={transformMode}
            onSelectItem={handleItemSelect}
            currentEnvironment={currentEnvironment}
            environmentSettings={environmentSettings}
            onEnvironmentLoad={() => setIsEnvironmentLoading(false)}
            onLoadProgress={setLoadingProgress}
            onModelLoad={handleModelLoad}
            onUpdateLightPosition={updateLightPosition}
            animatedModels={animatedModels}
          />
        </Canvas>
        {isEnvironmentLoading && (
          <div style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(0,0,0,0.5)',
            color: 'white',
            padding: '20px',
            borderRadius: '10px'
          }}>
            Loading Environment: {loadingProgress.toFixed(0)}%
          </div>
        )}
      </div>
    </div>
  )
}

const selectStyle = {
  padding: '10px',
  fontSize: '16px',
  backgroundColor: '#e0e0e0',
  color: 'black',
  border: '2px solid black',
  borderRadius: '8px',
  cursor: 'pointer',
  marginBottom: '10px'
};